import React from "react";
import TopBar from '../components/common/TopBar'
import BottomBar from "../components/common/BottomBar";
import FlowRegular from "../components/flow/FlowRegular";
const FlowRegularPage = () => {
  return (
    <div>
      <div
        id="flowRegularPage"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          flexDirection: 'column',
        }}
      >
        <TopBar />
        <FlowRegular />
        <BottomBar />
      </div>
    </div>
  );
};

export default FlowRegularPage;