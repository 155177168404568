import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Webcam from 'react-webcam';
import { saveAs } from 'file-saver';
import { Container, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import _ from 'lodash'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import MobileInput from '../common/MobileInput';
import CustomDialog from '../common/CustomDialog';

const { REACT_APP_KYC_BACKEND } = process.env;

export default ({IdFrontPage, IdBackPage, firstName, setFirstName, lastName, setlastName, address, setAddress, idNumber, setIdNumber, dob, setDob, countryCode,
  email,
  setEmail,
  mobileNumber,
  setMobileNumber,
  mobileCountryCode,
  setMobileCountryCode,
  backOneStep}) => {
    const {t} = useTranslation();
    const IdFrontTextExtractURL = REACT_APP_KYC_BACKEND + "/textExtract/extractText/IdFront/singapore"
    const IdBackTextExtractURL = REACT_APP_KYC_BACKEND + "/textExtract/extractText/IdBack/singapore"
    const [loading, setLoading] = useState(true);
    
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        const captureIdFrontDetails = async (image) => {
            const requestData = {
                img: image,
            };
            try {
            let textDetectFrontResponse = await axios.post(IdFrontTextExtractURL, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (textDetectFrontResponse.data.firstName !== '') {
                setFirstName(textDetectFrontResponse.data.firstName)
            }
            if (textDetectFrontResponse.data.lastName !== '') {
                setlastName(textDetectFrontResponse.data.lastName)
            }
            if (textDetectFrontResponse.data.IdNumber !== '') {
                setIdNumber(textDetectFrontResponse.data.IdNumber)
            }
            if (textDetectFrontResponse.data.dobTime !== '') {
                setDob(dayjs(textDetectFrontResponse.data.dobTime));
            }
            
            if(!textDetectFrontResponse.data.firstName && !textDetectFrontResponse.data.lastName && !textDetectFrontResponse.data.IdNumber && !textDetectFrontResponse.data.dobTime){
                setDialogOpen(true)
            }
            } catch (error) {
            }
        };
        const captureIdBackDetails = async (image) => {
            const requestData = {
                img: image,
            };
            try {
                let textDetectBackResponse = await axios.post(IdBackTextExtractURL, requestData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (textDetectBackResponse.data.address !== ', , ') {
                    setAddress({['addressLine1']: textDetectBackResponse.data.address})
                }
            } catch (error) {
            }
        };
        const fetchData = async () => {
            try {
              await Promise.all([
                captureIdFrontDetails(IdFrontPage),
                captureIdBackDetails(IdBackPage),
              ]);
            } catch (error) {
              // Handle error if needed
            } finally {
              setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleDobChange = (date) => {
        setDob(date);
     };
    const handleIdNumberChange = (event) => {
        setIdNumber(event.target.value);
    };
    const handleFirstnameChange = (event) => {
        setFirstName(event.target.value);
    };
    const handleLastnameChange = (event) => {
        setlastName(event.target.value);
    };
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
    // const handleMobileCountryCodeChange = (event) => {
    //   setMobileCountryCode(event.target.value);
    // };
    // const handleMobileNumberChange = (event) => {
    //   setMobileNumber(event.target.value);
    // };
    
    const handleAddressChange = (property, value) => {
        setAddress((prevAddress) => {
          const updatedAddress = {
            ...prevAddress,
            [property]: value,
          };
      
          if (countryCode === 'sg') {
            updatedAddress.satisfied = updatedAddress.addressLine1 !== '' && updatedAddress.postal !== '';
          } else if (countryCode === 'us') {
            updatedAddress.satisfied = updatedAddress.addressLine1 !== '' && updatedAddress.state !== '' && updatedAddress.postal !== '' && updatedAddress.city !== '';
          } else {
            updatedAddress.satisfied = updatedAddress.addressLine1 !== '';
          }
      
          return updatedAddress;
        });
      };
      const onDialogClose = (event, reason) => {
          if(reason == "backdropClick" || reason == "escapeKeyDown") return;
          setDialogOpen(false)
      }
      const onDialogAccept = (event) => {
          //accept reupload
          setDialogOpen(false)
          backOneStep()
      }
      const onDialogReject = (event) => {
          //reject reupload
          setDialogOpen(false)
      }
    function getAddressForm() {
        switch (countryCode) {
            case 'sg':
                return (
                    <div>
                        <TextField
                            id="outlined-controlled"
                            label={t('AnalyzeIDForm.addressLine1')}
                            variant="outlined"
                            fullWidth
                            value={address.addressLine1}
                            onChange={(event) => handleAddressChange('addressLine1', event.target.value)}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        <TextField
                            id="outlined-controlled"
                            label={t('AnalyzeIDForm.addressLine2')}
                            variant="outlined"
                            fullWidth
                            value={address.addressLine2}
                            onChange={(event) => handleAddressChange('addressLine2', event.target.value)}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        <TextField
                            id="outlined-controlled"
                            label={t('AnalyzeIDForm.postal')}
                            variant="outlined"
                            fullWidth
                            value={address.postal}
                            onChange={(event) => handleAddressChange('postal', event.target.value)}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                    </div>
                )
            case 'us':
                return (
                    <div>
                      <TextField
                        id="outlined-controlled-addressLine1"
                        label={t('AnalyzeIDForm.addressLine1')}
                        variant="outlined"
                        fullWidth
                        value={address.addressLine1}
                        onChange={(event) => handleAddressChange('addressLine1', event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                            id="outlined-controlled"
                            label={t('AnalyzeIDForm.addressLine2')}
                            variant="outlined"
                            fullWidth
                            value={address.addressLine2}
                            onChange={(event) => handleAddressChange('addressLine2', event.target.value)}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                      <TextField
                        id="outlined-controlled-city"
                        label={t('AnalyzeIDForm.city')}
                        variant="outlined"
                        fullWidth
                        value={address.city}
                        onChange={(event) => handleAddressChange('city', event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="outlined-controlled-state"
                        label={t('AnalyzeIDForm.state')}
                        variant="outlined"
                        fullWidth
                        value={address.state}
                        onChange={(event) => handleAddressChange('state', event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="outlined-controlled-postal"
                        label={t('AnalyzeIDForm.postal')}
                        variant="outlined"
                        fullWidth
                        value={address.postal}
                        onChange={(event) => handleAddressChange('postal', event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                );
            default:
                return (
                    <div>
                      <TextField
                        id="outlined-controlled-addressLine1"
                        label={t('AnalyzeIDForm.addressLine1')}
                        variant="outlined"
                        fullWidth
                        value={address.addressLine1}
                        onChange={(event) => handleAddressChange('addressLine1', event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                );
        }

    }
  return (
    <Suspense fallback="...loading"><div>
        { !loading && <Container
        disableGutters
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            
        }}
        >
            <Typography
            variant="h1"
            component="span"
            sx={{
                fontSize: '22px',
                fontWeight: 550,
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: 'black',
                mt: 0,
                mb: 5,
                ml: 1,
                fontFamily: 'system-ui'
            }}
            >
                {t('AnalyzeIDForm.confirmInformation')}
            </Typography>
            <Typography
            variant="h1"
            component="span"
            sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: 'black',
                mb: 5,
                ml: 1,
                fontFamily: 'system-ui'
            }}
            >
                {t('AnalyzeIDForm.content')}
            </Typography>
            <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '34ch', borderBottom: 0 },
                mt: 1
            }}
            noValidate
            >
        
                <div>
                    <TextField
                            id="outlined-controlled"
                            shrink={false}
                            label= {t('AnalyzeIDForm.first')}
                            value={firstName}
                            variant="outlined"
                            onChange={handleFirstnameChange} // Attach the onChange event handler
                            InputLabelProps={{
                                shrink: true, // Keep the label always shrunk above the input
                            }}
                    />
                    <TextField
                            id="outlined-controlled"
                            shrink={false}
                            label= {t('AnalyzeIDForm.last')}
                            value={lastName}
                            variant="outlined"
                            onChange={handleLastnameChange} // Attach the onChange event handler
                            InputLabelProps={{
                                shrink: true, // Keep the label always shrunk above the input
                            }}
                    />
                    <TextField
                            id="outlined-controlled"
                            shrink={false}
                            label= {t('AnalyzeIDForm.number')}
                            value={idNumber}
                            variant="outlined"
                            onChange={handleIdNumberChange} // Attach the onChange event handler
                            InputLabelProps={{
                                shrink: true, // Keep the label always shrunk above the input
                            }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t('AnalyzeIDForm.dob')}
                            value={dob}
                            onChange={handleDobChange}
                            format="DD/MM/YYYY" // Customize the date format
                            mask="__/__/____" // Add a mask for better input control (optional)
                            renderInput={(params) => (
                            <TextField {...params} variant="filled" />
                            )}
                    />
                    </LocalizationProvider>

                    <TextField
                      id="outlined-controlled"
                      shrink={false}
                      label= {t('AnalyzeForm.email')}
                      value={email}
                      variant="outlined"
                      onChange={handleEmailChange} // Attach the onChange event handler
                      InputLabelProps={{
                          shrink: true, // Keep the label always shrunk above the input
                      }}
                    />

                    <MobileInput
                      
                      mobileNumber={mobileNumber}
                      setMobileNumber={setMobileNumber}
                      mobileCountryCode={mobileCountryCode}
                      setMobileCountryCode={setMobileCountryCode}
                      //onChange={handleMobileNumberCHange} // Attach the onChange event handler
                      
                    />

                    {getAddressForm()}
                    <Box sx={{ width: '70ch' }}>
                      {/* <TextField
                        id="outlined-controlled"
                        shrink={false}
                        label={t('AnalyzeIDForm.address')}
                        value={address}
                        variant="outlined"
                        onChange={handleAddressChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> */}
                    </Box>
                </div>
            </Box>
        </Container>}
        {loading && <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px" // Set the desired height (adjust as needed)
          marginTop="170px"
          marginBottom="170px"
        >
          <CircularProgress size={100} color="primary" /> {/* Adjust the size and color */}
        </Box>}

        <CustomDialog onClose={onDialogClose} open={dialogOpen} 
            content={
                <Typography
                variant="h1"
                component="div"
                sx={{
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                    color: 'black',
                    mb: 2,
                    fontFamily: 'system-ui'
                }}
                >
                    {t('AnalyzeIDForm.ocrRetryMsg')}<br />
                    {t('AnalyzeIDForm.ocrRetryConfirmation')}
                </Typography>
            }
            footer={
                <>
                  <Button variant="contained" color="primary" 
                  sx={{ minWidth: '100px' ,fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none'}} 
                  onClick={onDialogAccept}>
                      {t('general.yes')}
                  </Button>
                  <Button variant="outlined" color="primary" 
                  sx={{ minWidth: '100px' ,fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none'}} 
                  onClick={onDialogReject}>   
                      {t('general.no')}
                  </Button>
                </>
            }
            />
    </div>
    </Suspense>
  );
}