import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { countriesData } from '../../helpers/all-country.js';
import { MuiTelInput } from 'mui-tel-input'

function MobileInput({
    mobileNumber,
    setMobileNumber,
    mobileCountryCode,
    setMobileCountryCode
}) {
    const {t} = useTranslation();
    const [mobiFull, setMobiFull] = useState("")

    const handleChange = (value, info)=>{
        // console.log(value)
        // console.log(info)
        setMobiFull(value)

        setMobileCountryCode(info.countryCallingCode ? "+"+info.countryCallingCode : "")
        setMobileNumber(info.nationalNumber || "")
    }

    useEffect(()=>{
        if(mobileCountryCode && mobileNumber){
            // console.log("set")
            setMobiFull( mobileCountryCode+ mobileNumber)
        }else if(mobileCountryCode && !mobileNumber){
            // console.log("set2")
            setMobiFull(mobileCountryCode)
        }else{
            //default to sg
            setMobiFull("+65")
        }
    },[])

    return (
        <MuiTelInput 
            id="outlined-controlled"
            shrink={false}
            label= {t('AnalyzeForm.mobileNumber')}
            variant="outlined"
            InputLabelProps={{
                shrink: true, // Keep the label always shrunk above the input
            }}
            value={mobiFull} 
            onChange={handleChange} />
    );
}

export default MobileInput;