import React from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsexports from "./aws-exports";
import FlowRegularPage from "./pages/FlowRegularPage";
import VerificationConsentPage from "./pages/VerificationConsentPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import InvalidAccessPage from "./pages/InvalidAccessPage";
import AuthenticateTokenPage from "./pages/AuthenticateTokenPage";
import { AppContextProvider } from "./contexts/StoreContext.jsx";

Amplify.configure(awsexports);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthenticateTokenPage />,
  },
  {
    path: "/consent",
    element: <VerificationConsentPage />,
  },
  {
    path: "/flow",
    element: <FlowRegularPage />,
  },
  {
    path: "/invalid",
    element: <InvalidAccessPage />,
  },
]);
function App() {
  return (
      <AppContextProvider>
        {process.env.NODE_ENV !== "production" && <div className="ribbon">{process.env.NODE_ENV}</div>}
          <RouterProvider router={router}></RouterProvider>
      </AppContextProvider>
  );
}

export default App;
