import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';


function VerificationConsent() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const handlePrivacyPolicy = () => {
        window.open(`https://central.meyzer360.com/privacy`)
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const handleContinue = () => {
        navigate('/flow')
    };

    const isMobileWidth = () => {
        return window.innerWidth <= 774;
    };

    const isExtraShort = () => {
        return window.innerHeight <= 752;
    };
    const handleInvalid = useCallback(() => {
        navigate('/invalid');
    }, [navigate]);

    const PrivacyPolicyLink = () => (
        <div>
        *
          {t('verificationConsent.agreement')}{' '}
          <button
            // href="#"
            onClick={handlePrivacyPolicy}
            style={{ color: '#1976d2', cursor: 'pointer' }}
        >
      {t('verificationConsent.policyLink')}
    </button>
    .
        </div>
    );
    useEffect(()=>{
        const stores = JSON.parse(sessionStorage.getItem("mkyc"))
        if(!stores){
            handleInvalid()
        }
    },[handleInvalid])
    return (
        <Suspense fallback="...loading">
    <Container
      maxWidth="false"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: isMobileWidth() ? '100vh' : '694px',
        width: 'calc(100% - 2rem)',
        maxWidth: '774px',
        border: '1px solid lightgrey',
        borderRadius: '4px',
      }}
    >
        <Typography
        variant="h1"
        component="span"
        sx={{
            fontSize: '22px',
            fontWeight: 550,
            lineHeight: '24px',
            letterSpacing: '0px',
            textAlign: 'left',
            color: 'black',
            mt: 0,
            mb:5,
            fontFamily: 'system-ui'
        }}
        >
            {t('verificationConsent.title')}
        </Typography>

        <Typography
        variant="body1"
        component="span"
        sx={{
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#333853',
            mb: 5,
            fontFamily: 'system-ui'
        }}
        >
            {t('verificationConsent.statement')}
        </Typography>
        <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
            label=<PrivacyPolicyLink />
        />
        <Box display="flex" justifyContent="flex-end" sx={{borderTop: isMobileWidth() ? 'unset' : '1px solid lightgrey', mt: isExtraShort? 3 : 20, width: '100%'}}>
            <Button variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '16px',fontWeight: 500,textTransform: 'none', mt:3, mb:0}} onClick={handleContinue} disabled={!isChecked}>
                {t('verificationConsent.continueB')}
            </Button>
        </Box>
    </Container>
    </Suspense>
  );
}
export default VerificationConsent;