import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import _ from 'lodash'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import MobileInput from '../common/MobileInput';
import CustomDialog from '../common/CustomDialog';

const { REACT_APP_KYC_BACKEND } = process.env;

export default ({passportDocument, expiryDate, issueDate, passportNumber, setExpiryDate, setissueDate, setPassportNumber, 
    firstName,
    setFirstName,
    lastName,
    setlastName,
    dob,
    setDob,
    email,
    setEmail,
    mobileNumber,
    setMobileNumber,
    mobileCountryCode,
    setMobileCountryCode,
    backOneStep}) => {
    const {t} = useTranslation();
    const textExtractURL = REACT_APP_KYC_BACKEND + "/textExtract/extractText/passport/singapore"
    const [loading, setLoading] = useState(true);

    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        const captureTextDetails = async (image) => {
            const requestData = {
                img: image,
            };
            try {
                let textDetectResponse = await axios.post(textExtractURL, requestData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (textDetectResponse.data.doe !== '') {
                    setExpiryDate(dayjs(textDetectResponse.data.doe));
                }
                if (textDetectResponse.data.doi !== '') {
                    setissueDate(dayjs(textDetectResponse.data.doi));
                }
                if (textDetectResponse.data.passportNum !== '') {
                    setPassportNumber(textDetectResponse.data.passportNum);
                }

                if(!textDetectResponse.data.passportNum && !textDetectResponse.data.doi && !textDetectResponse.data.doe){
                    setDialogOpen(true)
                }
            } catch (error) {
            // Handle error if needed
            } finally {
                setLoading(false);
            }
        };
        captureTextDetails(passportDocument);
        
    }, []);

    const handleExpDateChange = (date) => {
        setExpiryDate(date);
      };
    const handleIssueDateChange = (date) => {
        setissueDate(date);
    };
    const handlePassportNumberChange = (event) => {
        setPassportNumber(event.target.value);
    };
    const handleFirstnameChange = (event) => {
        setFirstName(event.target.value);
    };
    const handleLastnameChange = (event) => {
        setlastName(event.target.value);
    };
    const handleDobChange = (date) => {
        setDob(date);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const onDialogClose = (event, reason) => {
        if(reason == "backdropClick" || reason == "escapeKeyDown") return;
        setDialogOpen(false)
    }
    const onDialogAccept = (event) => {
        //accept reupload
        setDialogOpen(false)
        backOneStep()
    }
    const onDialogReject = (event) => {
        //reject reupload
        setDialogOpen(false)
    }
  return (
    <Suspense fallback="...loading">
    <div>
        { !loading && <Container
        disableGutters
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
        }}
        >
            <Typography
            variant="h1"
            component="span"
            sx={{
                fontSize: '22px',
                fontWeight: 550,
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: 'black',
                mt: 0,
                mb: 5,
                ml: 1,
                fontFamily: 'system-ui'
            }}
            >
                {t('AnalyzePassportForm.confirmInformation')}
            </Typography>
            <Typography
            variant="h1"
            component="span"
            sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: 'black',
                ml: 1,
                mb: 5,
                fontFamily: 'system-ui'
            }}
            >
                {t('AnalyzePassportForm.content')}
            </Typography>
            <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '34ch', borderBottom: 0 },
                mt: 1
            }}
            noValidate
            >
        
        <div>
        <TextField
                id="outlined-controlled"
                shrink={false}
                label= {t('AnalyzeIDForm.first')}
                value={firstName}
                variant="outlined"
                onChange={handleFirstnameChange} // Attach the onChange event handler
                InputLabelProps={{
                    shrink: true, // Keep the label always shrunk above the input
                }}
        />
        <TextField
                id="outlined-controlled"
                shrink={false}
                label= {t('AnalyzeIDForm.last')}
                value={lastName}
                variant="outlined"
                onChange={handleLastnameChange} // Attach the onChange event handler
                InputLabelProps={{
                    shrink: true, // Keep the label always shrunk above the input
                }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={t('AnalyzeIDForm.dob')}
                value={dob}
                onChange={handleDobChange}
                format="DD/MM/YYYY" // Customize the date format
                mask="__/__/____" // Add a mask for better input control (optional)
                renderInput={(params) => (
                <TextField {...params} variant="filled" />
                )}
        />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={t('AnalyzePassportForm.expiry')}
                value={expiryDate}
                onChange={handleExpDateChange}
                format="DD/MM/YYYY" // Customize the date format
                mask="__/__/____" // Add a mask for better input control (optional)
                renderInput={(params) => (
                <TextField {...params} variant="filled" />
                )}
            />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={t('AnalyzePassportForm.issue')}
                value={issueDate}
                onChange={handleIssueDateChange}
                format="DD/MM/YYYY" // Customize the date format
                mask="__/__/____" // Add a mask for better input control (optional)
                renderInput={(params) => (
                <TextField {...params} variant="filled" />
                )}
            />
            </LocalizationProvider>
            <TextField
                id="outlined-controlled"
                shrink={false}
                label= {t('AnalyzePassportForm.number')}
                value={passportNumber}
                variant="outlined"
                onChange={handlePassportNumberChange} // Attach the onChange event handler
                InputLabelProps={{
                    shrink: true, // Keep the label always shrunk above the input
                }}
            />

            <TextField
                id="outlined-controlled"
                shrink={false}
                label= {t('AnalyzeForm.email')}
                value={email}
                variant="outlined"
                onChange={handleEmailChange} // Attach the onChange event handler
                InputLabelProps={{
                    shrink: true, // Keep the label always shrunk above the input
                }}
            />

            <MobileInput
                
                mobileNumber={mobileNumber}
                setMobileNumber={setMobileNumber}
                mobileCountryCode={mobileCountryCode}
                setMobileCountryCode={setMobileCountryCode}
                //onChange={handleMobileNumberCHange} // Attach the onChange event handler
                
            />
        </div>
        </Box>
        </Container>}
        {loading && <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100px" // Set the desired height (adjust as needed)
              marginTop="170px"
              marginBottom="170px"
            >
              <CircularProgress size={100} color="primary" /> {/* Adjust the size and color */}
            </Box>}

            <CustomDialog onClose={onDialogClose} open={dialogOpen} 
            content={
                <Typography
                variant="h1"
                component="div"
                sx={{
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                    color: 'black',
                    mb: 2,
                    fontFamily: 'system-ui'
                }}
                >
                    {t('AnalyzePassportForm.ocrRetryMsg')}<br />
                    {t('AnalyzePassportForm.ocrRetryConfirmation')}
                </Typography>
            }
            footer={
                <>
                    <Button variant="contained" color="primary" 
                    sx={{ minWidth: '100px' ,fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none'}} 
                    onClick={onDialogAccept}>
                        {t('general.yes')}
                    </Button>
                    <Button variant="outlined" color="primary" 
                    sx={{ minWidth: '100px' ,fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none'}} 
                    onClick={onDialogReject}>   
                        {t('general.no')}
                    </Button>
                </>
            }
            />
    </div>
    </Suspense>
  );
}