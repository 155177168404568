import React, { useState } from 'react';
import { Button, Container } from '@mui/material';
import LivenessTest from '../services/LivenessTest';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FaceVerificationResult from '../ui/FaceVerificationResult';
import FaceVerificationMessage from '../ui/FaceVerificationMessage';
import { useEffect } from 'react';
import axios from "axios";
import s3Upload from "../../helpers/s3Upload"
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';


const { REACT_APP_KYC_BACKEND } = process.env;

const FaceVerification = ({
    onContinueClick, 
    liveTestDetails, 
    setLiveTestDetails, 
    auditVideo, 
    setAuditVideo, 
    createLivenessApiData, 
    setCreateLivenessApiData,
    setS3Temp}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const {t} = useTranslation();
    const createurl = REACT_APP_KYC_BACKEND + '/faceliveness/createSession'

    const uploadFiles = s3Upload.uploadFiles
    const getS3Link = s3Upload.getS3Link
    const generateRandomUID = s3Upload.generateRandomUID
    
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        console.log(liveTestDetails); // Verify if the liveTestDetails state is updated
    }, [liveTestDetails]);  
    const handleStart = () => {
        setCurrentStep(1);
    }
    const fetchCreateLiveness = async () => {
            let reqInstance = axios.create({
            })
            try {
                const response = await reqInstance.get(createurl);
                console.log('response sess ', response)
                setCreateLivenessApiData(response.data.SessionId);

                setLoading(false);
            } catch (error) {
            console.error('Error:', error);
            }

    };
    const handleTryAgain = () => {
        // console.log("try again")
        setLoading(true);
        setCurrentStep(1);
    }
    const handleOnComplete = async (details) => {
        await Promise.resolve();
        setLiveTestDetails(details);
        console.log(auditVideo)
        setCurrentStep(2);
    }
    const handleContinue = async () => {
        if (onContinueClick) {
            setSubmitting(true)
            let uid = generateRandomUID()
            // console.log(liveTestDetails.liveImage.data)
            
            let scBlob = new Blob([new Uint8Array(liveTestDetails.liveImage.data)]);
            // let buff=Buffer.from(liveTestDetails.liveImage.data, 'base64')
            // console.log(buff)
            // console.log("----------scblob-----------")
            // console.log(scBlob)
            // let livenessImgFile = new File([buff], uid+".jpg", { type: 'image/jpeg' })
            let livenessImgFile = new File([scBlob], uid+".jpg", { type: 'image/jpeg' })

            let uidVid = generateRandomUID()
            let auditVideoFile = new File([auditVideo], uidVid+".mp4", { type: 'video/mp4' })
            
            const imgS3Link = await getS3Link('image/jpeg')
            const auditVideoS3Link = await getS3Link('video/mp4')
            
            Promise.all([
                await uploadFiles(imgS3Link,livenessImgFile),
                await uploadFiles(auditVideoS3Link,auditVideoFile)
            ]).then(async (res)=>{
                console.log("complete upload s3=-====> ",res)
                console.log(res[0],res[1])
                setS3Temp(prev=>({
                    ...prev,
                    livenessImg:res[0],
                    auditVideo:res[1]
                }))
                
                onContinueClick();

            }).catch(err=>{
                console.error("error upload files", err)
            })
            
        }
    };
    const handleBack = () => {
        console.log("Back clicked")
    };

    return (
        <Suspense fallback="...loading">
        <Container disableGutters maxWidth="false" >
            {!submitting && currentStep !== 2 && <Typography
            variant="h1"
            component="span"
            sx={{
                fontSize: '22px',
                fontWeight: 550,
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: 'black',
                mt: 2,
                mb: 8,
                fontFamily: 'system-ui',
            }}
            >
                {t('FaceVerification.title')}
            </Typography>}
            {submitting && <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100px" // Set the desired height (adjust as needed)
              marginTop="170px"
              marginBottom="170px"
            >
              <CircularProgress size={100} color="primary" /> {/* Adjust the size and color */}
            </Box>}
            {currentStep === 0 && 
            <FaceVerificationMessage></FaceVerificationMessage>}
            {currentStep === 1 && (
                <div>
                    <div style={{
                        fontSize: '18px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#333853',
                        // mt: isExtraShort()? 1 : 9,
                        fontFamily: 'system-ui',
                        display:'block',
                        width:'100%',
                        marginBottom:'1rem',
                        marginTop:'0.5rem'
                    }}
                    >
                        <p>{
                            `${t('FaceVerification.preVerifyMsg')}`
                        }</p>
                    </div>
                    <LivenessTest 
                        onComplete={handleOnComplete} 
                        auditVideo={auditVideo} 
                        setAuditVideo={setAuditVideo}
                        cancelLivenessTest={handleTryAgain}
                        fetchCreateLiveness={fetchCreateLiveness}
                        createLivenessApiData={createLivenessApiData}
                        setCreateLivenessApiData={setCreateLivenessApiData}
                        loading={loading}
                        setLoading={setLoading}
                        />
                </div>
            )}
            {!submitting && currentStep === 2 && liveTestDetails !==undefined && <FaceVerificationResult liveImage={liveTestDetails.liveImage}/>}
            {currentStep === 0 && 
            <Box display="flex" justifyContent="flex-end" sx={{borderTop: '1px solid lightgrey', mt: 3}}>
                <Button variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleStart} >
                    {t('FaceVerification.proceed')}
                </Button>
            </Box> }
            <Box display="flex" justifyContent="space-between" sx={{borderTop: '1px solid lightgrey', mt: 3}}>
                
                {currentStep === 2 && <Button variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3, color: '#777777',backgroundColor: 'white',border: '1px solid #777777',boxShadow: 'none'}} onClick={handleTryAgain}>
                    {/* Try Again */}
                    {t('general.no')}
                </Button> }
                {currentStep === 2 && liveTestDetails.liveImage && <Button variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleContinue} disabled={submitting}>
                    {/* Continue */}
                    {t('general.yes')}
                </Button> }
            </Box>
        </Container>
        </Suspense>
    );
};
export default FaceVerification