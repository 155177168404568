import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import FilePresentIcon from '@mui/icons-material/FilePresent';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  padding:'16px'
}));

export default function DetailsSummaryTable({ 
  data,
  title
}) {
  

  const openImg = (ev) => {
    let image = new Image();
    image.src = ev.target.src;

    let w = window.open("");
    w.document.write(image.outerHTML);
    // console.log(ev)
    // console.log(ev.target.src)
    // window.open(ev.target.src, "_blank")
  }
  const openFile = (file) => {
    const blobData = new Blob([file], { type: file.type });
    const blobUrl = URL.createObjectURL(blobData);
    window.open(blobUrl, '_blank');
  }
  return (
    <div>

      <Typography
      variant="h1"
      component="div"
      sx={{
          fontSize: '16px',
          fontWeight: 550,
          lineHeight: '14px',
          letterSpacing: '0px',
          
          color: 'black',
          mt: 0,
          mb: 2,
          fontFamily: 'system-ui'
      }}
      >
        {title}
      </Typography>
      
      <TableContainer sx={{ minWidth: 280 }}>
        <Table sx={{ minWidth: 280, mb:4 }} aria-label="summary">
          
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.label}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left" sx={{ padding:"8px" }}>
                  {row.label}
                </TableCell>
                {row.img && !row.isFileObj && <TableCell align="right" sx={{ padding:"8px" }} >
                  {/* span onClick={showImgDialog(row.img)} className="cursor-pointer">{row.label}</span> */}
                  <img align="right" src={row.img} onClick={openImg} style={{
                    maxWidth:200,
                    cursor:"pointer"
                  }} />
                </TableCell>}
                {row.img && row.isFileObj && <TableCell align="right" sx={{ padding:"8px" }} >
                  {<div style={{cursor:"pointer"}} onClick={()=>{openFile(row.img)}}>
                    <FilePresentIcon sx={{marginRight:'1rem'}}></FilePresentIcon>
                    <span style={{verticalAlign:'middle'}}>{row.img.name}</span>
                  </div>}
                </TableCell>}
                {row.value && <TableCell align="right" sx={{ padding:"8px" }}>
                  {row.value}
                </TableCell>}
              </TableRow>
            ))}
            
          </TableBody>
        </Table>
      </TableContainer>

      
    </div>
    
  );
}
