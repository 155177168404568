import * as React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
// import css from './CustomDialog.css'

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  padding:'20px 24px',
  justifyContent: 'center',
  gap: '5rem'
}));

export default function CustomDialog(props) {
  const { open, onClose, content, title, footer } = props;

  const handleClose = (ev, reason) => {
    onClose(ev, reason);
  };


  return (
    <Dialog open={open} onClose={handleClose} sx={{minWidth:"320px"}} >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {content}
      </DialogContent>
      <CustomDialogActions>
        {footer}
      </CustomDialogActions>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

