import React, { useRef, useState, useEffect } from 'react';
import Webcam from "react-webcam";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Box from '@mui/material/Box';
import LoopIcon from '@mui/icons-material/Loop';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Container from '@mui/material/Container';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

function UploadDocWebcam({type, document, setDocument}) {
    const {t} = useTranslation();
    const webcamRef = React.useRef(null);
    const [showCamera, setShowCamera] = useState(false);
    const [pictureTaken, setPictureTaken] = useState(false);
    const [pictureConfirmed, setPictureConfirmed] = useState(false);
    const [snap, setSnap] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const interval = useRef()
    const [startTimer, setStartTimer] = useState(false)
    const [endTime, setEndTime] = useState(null)
    const countdownDuration = 3;
    const handleClick = () => {
        setShowCamera(true);
    };
    const handleContinue = () => {
        setPictureConfirmed(true);
        setDocument(snap);
        setShowCamera(false);
    }
    const handleBack = () => {
        if (!pictureConfirmed){
            setPictureTaken(false)
            setDocument(null)
        }
        if (pictureConfirmed){
            setPictureTaken(true)
        }
        setShowCamera(false);
        handleCancelCountdown()
    }
    const handleTakePicture = () => {
        const webcamInstance = webcamRef.current;
        const desiredWidth = 602;
        const aspectRatio = webcamInstance.video.videoWidth / webcamInstance.video.videoHeight;
        const calculatedHeight = Math.round(desiredWidth / aspectRatio);

        const screenshot = webcamInstance.getScreenshot({
            width: desiredWidth,
            height: calculatedHeight,
        });
        setSnap(screenshot)
        setPictureTaken(true)
    }
    const handleCountdown = () => {
        setStartTimer(true)
        setEndTime(new Date().getTime() + 1000*countdownDuration)
    }
    const handleCancelCountdown = () => {
        clearInterval(interval.current)
        setStartTimer(false)
        setEndTime(null)
    }
    const handleRetake = () => {
        setPictureTaken(false)
    }
    const isMobileWidth = () => {
        return window.innerWidth < 774;
    };
    useEffect(() => {
        // console.log("setStartTimerUseEffect")
        if(startTimer === true){
            let currentTime = new Date().getTime();
            interval.current = setInterval(()=>{
                const timePassed = (new Date().getTime() - currentTime) / 1000;
                setElapsedTime(timePassed);
                if(new Date().getTime() > endTime){
                    // console.log("clear timer")
                    clearInterval(interval.current)
                    setStartTimer(false)
                    setEndTime(null)
                    handleTakePicture()
                }
            },1000)
        }
        
        return () => {
            clearInterval(interval.current);
            setElapsedTime(0)
        }
    }, [startTimer]);

    useEffect(()=>{
        //buggy, querySelector null

        // const bodyElement = document.querySelector('body');
        // const flowRegularPageElement = document.querySelector('#flowRegularPage');

        // if (bodyElement && flowRegularPageElement) {
        //     if (showCamera) {
        //         bodyElement.style.overflow = 'hidden';
        //         flowRegularPageElement.style.overflow = 'hidden';
        //     } else {
        //         bodyElement.style.overflow = null;
        //         flowRegularPageElement.style.overflow = null;
        //     }
        // }
    },[showCamera])


    return (
        <Suspense fallback="...loading">
        <div>
            {!showCamera && <button
            onClick={handleClick}
            style={{
                backgroundColor: '#F7F7F7',
                border: '1px dashed grey',
                borderRadius: 1,
                width: isMobileWidth() ? '100%' : '300px',
                // height: '137px',
                minHeight:'230px',
                cursor: 'pointer',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            >
            {!pictureTaken && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {
                    type == "IdFrontPage" && (
                        <>
                            <img src={require('../../resources/sample_id_front.png')} style={{maxHeight:'120px', boxShadow:'0 0 18px #CCC'}} />
                            <span style={{ marginTop: '10px', color: 'black' }}>{t('UploadPanel.uploadIdFront')}</span>
                        </>
                    )
                }
                {
                    type == "IdBackPage" && (
                        <>
                            <img src={require('../../resources/sample_id_back.png')} style={{maxHeight:'120px', boxShadow:'0 0 18px #CCC'}} />
                            <span style={{ marginTop: '10px', color: 'black' }}>{t('UploadPanel.uploadIdBack')}</span>
                        </>
                    )
                }
                {
                    type == "Passport" && (
                        <>
                            <img src={require('../../resources/sample_passport.png')} style={{maxHeight:'200px', boxShadow:'0 0 18px #CCC'}} />
                            <span style={{ marginTop: '10px', color: 'black' }}>{t('UploadPanel.uploadPassport')}</span>
                        </>
                    )
                }
                {
                    type == "EpFront" && (
                        <>
                            <img src={require('../../resources/sample_id_front.png')} style={{maxHeight:'120px', boxShadow:'0 0 18px #CCC'}} />
                            <span style={{ marginTop: '10px', color: 'black' }}>{t('UploadPanel.uploadEpFront')}</span>
                        </>
                    )
                }
                {
                    type == "EpBack" && (
                        <>
                            <img src={require('../../resources/sample_id_back.png')} style={{maxHeight:'120px', boxShadow:'0 0 18px #CCC'}} />
                            <span style={{ marginTop: '10px', color: 'black' }}>{t('UploadPanel.uploadEpBack')}</span>
                        </>
                    )
                }
            </div>
            )}
            { pictureTaken && <div style={{ position: "relative"}}>
                        <img src={document} alt="Preview Image" />
            </div>}
            </button>}
            {showCamera && 
            <div className="webcam__parentContainer">
                <div style={{ position: 'relative', width: isMobileWidth() ? 'auto' : '602px', height: 'auto', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                    <Typography className={"webcam__instruction"} style={{ fontSize: "18px", weight: '700', color: 'white', textAlign: 'center', fontFamily: 'system-ui',
                        maxWidth:isMobileWidth() ? '240px' : 'unset', margin:isMobileWidth() ? 'auto' : 'unset'}}>

                    { type == "IdFrontPage" && t('UploadPanel.uploadInstructionIdFront') }
                    { type == "IdBackPage" && t('UploadPanel.uploadInstructionIdBack')}
                    { type == "Passport" && t('UploadPanel.uploadInstructionPassport')}
                    { type == "EpFront" && t('UploadPanel.uploadInstructionEpFront')}
                    { type == "EpBack" && t('UploadPanel.uploadInstructionEpBack')}
                    <br></br>
                    {t('UploadPanel.uploadInstruction2')}

                    </Typography>
                    { !pictureTaken && <Container className={"webcam__container"} style={{position:'relative', padding: isMobileWidth() ? '0' : '0 16px'}}>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            height={497}
                            width={602}
                            screenshotQuality={1}
                            style={{
                                position: 'relative',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                zIndex: 1
                            }}
                        />
                        <Box className="overlayImg" component="img" src={require('../../resources/overlay.png')} sx={{ 
                            position: 'absolute', top:-1, objectFit: 'cover',zIndex: 2, width: isMobileWidth() ? '100%' : 'auto'}} alt="Logo" />
                        {startTimer && (
                            <div className={'animationFadeOut'} style={{
                                position: 'absolute',
                                bottom: '50%',
                                left: '50%',
                                transform: 'translate(-50%,50%)',
                                width: '200px',
                                height: '200px',
                                borderRadius: '50%',
                                background: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontSize: '96px',
                                fontWeight: 'bold',
                                zIndex: 2,
                                }}>
                                    {countdownDuration - elapsedTime.toFixed(1)}
                            </div>
                        )}
                    </Container>
                    }
                    { pictureTaken && <div style={{ position: "relative", width: isMobileWidth() ? 'auto' : '602px', height: isMobileWidth() ? 'auto' : 'auto', marginTop: isMobileWidth() ? '10px' : '0',padding: isMobileWidth() ? '0' : '25px'}}>
                        <img src={snap} alt="Preview Image" />
                    </div>
                    }
                    <div className={"webcam__buttons"}>
                        <div style={{ width:'100px' }}>
                            <Button
                                color="primary"
                                sx={{
                                    color: 'white',
                                    fontFamily: 'system-ui',
                                    fontSize: '17px',
                                    fontWeight: 500,
                                    textTransform: 'none',
                                }}
                                onClick={handleBack}
                                startIcon={<ArrowBackIosIcon sx={{ color: 'white' }} />}
                                >
                                {t('UploadPanel.backB')}
                            </Button>
                        </div>

                        <div>
                            {!pictureTaken && <Button
                                disabled={startTimer}
                                variant="contained"
                                color="primary"
                                onClick={handleCountdown}
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: 'white',
                                    width: '64px',
                                    height: '64px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                                    // margin: isMobileWidth() ? '-100px 0.5rem 0' : 'none'
                                }}
                                >
                                <CameraAltIcon sx={{ color: 'black' }} />
                            </Button>}
                            {pictureTaken && <Button
                                color="primary"
                                sx={{
                                    color: 'white',
                                    fontFamily: 'system-ui',
                                    fontSize: '17px',
                                    fontWeight: 500,
                                    textTransform: 'none',
                                    // margin: isMobileWidth() ? '-120px 0.5rem 0' : 'none',
                                    width: isMobileWidth() ? '180px' : '200px', 
                                    border: '1px solid'
                                }}
                                onClick={handleRetake}
                                startIcon={<LoopIcon sx={{ color: 'white' }} />}
                                >
                                {t('UploadPanel.retake')}
                            </Button> }
                        </div>

                        <div style={{width:'100px'}}>
                            {pictureTaken && <Button
                                color="primary"
                                sx={{
                                    color: 'white',
                                    fontFamily: 'system-ui',
                                    fontSize: '17px',
                                    fontWeight: 500,
                                    textTransform: 'none',
                                    // marginLeft: 20
                                }}
                                onClick={handleContinue}
                                endIcon={<ArrowForwardIosIcon sx={{ color: 'white' }} />}
                                >
                                {t('UploadPanel.continueB')}
                            </Button> }
                        </div>
                    </div>
                </div>
                
            </div>}
        </div>
        </Suspense>
    );
}

export default UploadDocWebcam;