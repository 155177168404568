import React, { useState } from 'react';
import { Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import s3Upload from "../../helpers/s3Upload"
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import LocalForeignerCheck from '../common/LocalForeignerCheck'
import IdUpload from '../ui/IdUpload'
import PassportUpload from '../ui/PassportUpload';
import AnalyzeIdForm from '../services/AnalyzeIdForm';
import AnalyzePassportForm from '../services/AnalyzePassportForm';
import ProofOfAddressUpload from '../ui/ProofOfAddressUpload';
import CustomDialog from '../common/CustomDialog';
import EpUpload from '../ui/EpUpload';
import AnalyzeEpForm from '../services/AnalyzeEpForm'

const PassportIDVerification = (props) => {
    const {
        kycLocale,
        onBackClick,
        onContinueClick,
    
        passportDocument,
        setPassportDocument,
        expiryDate,
        setExpiryDate,
        issueDate,
        setissueDate,
        passportNumber,
        setPassportNumber,
    
        IdFrontPage,
        setIdFrontPage,
        IdBackPage,
        setIdBackPage,
        firstName,
        setFirstName,
        lastName,
        setlastName,
        address,
        setAddress,
        idNumber,
        setIdNumber,
        dob,
        setDob,
        s3Temp,
        setS3Temp,
        countryCode,
        setCountryCode,
    
        isLocalForeign,
        setIsLocalForeign,
        
        proofOfAddress,
        setProofOfAddress,
    
        email,
        setEmail,
        mobileNumber,
        setMobileNumber,
        mobileCountryCode,
        setMobileCountryCode,
    
        employmentPassFront,
        setEmploymentPassFront,
        employmentPassBack,
        setEmploymentPassBack,
        finNumber,
        setFinNumber,
        isEpUpload,
        setIsEpUpload,
        occupation,
        setOccupation,
        epDateOfApplication,
        setEpDateOfApplication,
        epDateOfIssue,
        setEpDateOfIssue,
        epDateOfExpiry,
        setEpDateOfExpiry,
        epGender,
        setEpGender,
        employer,
        setEmployer,
        epBackQR,
        setEpBackQR
    } = props;

    const {t} = useTranslation();
    const uploadFiles = s3Upload.uploadFiles
    const getS3Link = s3Upload.getS3Link
    const generateRandomUID = s3Upload.generateRandomUID
    
    const [currentStep, setCurrentStep] = useState(0);
    const [submitting, setSubmitting] = useState(false)
    const [epDialogOpen, setEpDialogOpen] = useState(false)

    

    const handleStartIDUpload = () => {
        if(isLocalForeign == "local") setCountryCode("sg")

        setCurrentStep(1);
    }
    const handleFinishPassportUpload = async (details) => {
        setCurrentStep(2);
    }
    const handleContinuePassport = async () => {
        setEpDialogOpen(true)
        // setCurrentStep(3);
    };
    const onEpDialogAccept = (event) => {
        //accept ep upload
        setEpDialogOpen(false)
        setIsEpUpload(true)
        setCurrentStep(3);
    }
    const onEpDialogReject = (event) => {
        //reject ep upload
        setEpDialogOpen(false)
        setIsEpUpload(false)
        
        setEmploymentPassFront(null)
        setEmploymentPassBack(null)
        setCurrentStep(5);
    }

    const handleFinishEpUpload = () => {
        setCurrentStep(4);
    }

    const handleContinueEp = () => {
        setCurrentStep(5);
    }

    const onEpDialogClose = (event, reason) => {
        
        if(reason == "backdropClick" || reason == "escapeKeyDown") return;
        setEpDialogOpen(false)
        
    }

    const handleContinueID = async () => {
        if (onContinueClick) {
            setSubmitting(true)
            let scRespFront = await fetch(IdFrontPage)
            let scBlobFront = await scRespFront.blob()

            let scRespBack = await fetch(IdBackPage)
            let scBlobBack = await scRespBack.blob()
            
            let uid = generateRandomUID()
            let idFrontFile = new File([scBlobFront], uid+".jpg", { type: 'image/jpeg' })

            let uidBack = generateRandomUID()
            let idBackFile = new File([scBlobBack], uidBack+".jpg", { type: 'image/jpeg' })

            const imgFrontS3Link = await getS3Link('image/jpeg')
            const imgBackS3Link = await getS3Link('image/jpeg')
            
            Promise.all([
                await uploadFiles(imgFrontS3Link,idFrontFile),
                await uploadFiles(imgBackS3Link,idBackFile)
            ]).then(async (res)=>{
                console.log("complete upload s3=-====> ",res)
                console.log(res[0])
                setS3Temp(prev=>({
                    ...prev,
                    idFront:res[0],
                    idBack:res[1]
                }))
                
                onContinueClick();

            }).catch(err=>{
                console.error("error upload files", err)
            })
            // onContinueClick();
        }
    };
    const handleFinishMiscDocsUpload = async () => {
        if (onContinueClick) {
            
            setSubmitting(true)
            let files = [passportDocument, employmentPassFront, employmentPassBack]
            const req = files.filter(v=>v).map(async fileBase64=>{
                let scResp = await fetch(fileBase64)
                let scBlob = await scResp.blob()
                
                let uid = generateRandomUID()
                let convertToFile = new File([scBlob], uid+".jpg", { type: 'image/jpeg' })

                const imgS3Link = await getS3Link('image/jpeg')
                return await uploadFiles(imgS3Link, convertToFile)

            })

            Promise.all(req).then(async (res)=>{
                // console.log("complete upload s3=-====> ",res)
                // console.log(res[0])
                setS3Temp(prev=>({
                    ...prev,
                    passport:res[0],
                    epFront:res[1],
                    epBack:res[2]
                }))
                
                onContinueClick();

            }).catch(err=>{
                console.error("error upload files", err)
            })
            
        }

    }
    const handleBackStep = () => {
        if (onBackClick) {
            resetAllFileInputs()
            
            onBackClick();
        }
    };
    const handleBack = () => {
        if(!isEpUpload && isLocalForeign == "foreigner" && currentStep == 5){
            setCurrentStep(1);
        }else{
            setCurrentStep(currentStep-1);
        }
    };

    const resetAllFileInputs = () => {
        setPassportDocument(null)
        setIdFrontPage(null)
        setIdBackPage(null)
        setPassportDocument(null)
        setProofOfAddress(null)
        setEmploymentPassFront(null)
        setEmploymentPassBack(null)
    }

    return (
        <Suspense fallback="...loading">
        <Container disableGutters maxWidth="false" sx={{padding: 0}}>
            {currentStep === 0 && <LocalForeignerCheck kycLocale={kycLocale} setCountryCode={setCountryCode} countryCode={countryCode} isLocalForeign={isLocalForeign} setIsLocalForeign={setIsLocalForeign}/>}

            {currentStep === 1 && isLocalForeign=="local" && <IdUpload IdFrontPage={IdFrontPage} setIdFrontPage={setIdFrontPage} IdBackPage={IdBackPage} setIdBackPage={setIdBackPage}/>}

            {currentStep === 2 && isLocalForeign=="local" && <AnalyzeIdForm 
                IdFrontPage={IdFrontPage} 
                IdBackPage={IdBackPage} 
                firstName={firstName} 
                setFirstName={setFirstName} 
                lastName={lastName } 
                setlastName={setlastName} 
                address={address} 
                setAddress={setAddress} 
                idNumber={idNumber} 
                setIdNumber={setIdNumber} 
                dob={dob} 
                setDob={setDob} 
                countryCode={countryCode}

                email={email}
                setEmail={setEmail}
                mobileNumber={mobileNumber}
                setMobileNumber={setMobileNumber}
                mobileCountryCode={mobileCountryCode}
                setMobileCountryCode={setMobileCountryCode}
                backOneStep={handleBack}
                />}


            {currentStep === 1 && isLocalForeign=="foreigner" && <PassportUpload passportDocument={passportDocument} setPassportDocument={setPassportDocument}/>}
            {currentStep === 2 && isLocalForeign=="foreigner" && <AnalyzePassportForm 
                passportDocument={passportDocument} 
                expiryDate={expiryDate} 
                issueDate={issueDate} 
                passportNumber={passportNumber } 
                setExpiryDate={setExpiryDate} 
                setissueDate={setissueDate} 
                setPassportNumber={setPassportNumber}
                
                firstName={firstName} 
                setFirstName={setFirstName} 
                lastName={lastName} 
                setlastName={setlastName} 
                dob={dob} 
                setDob={setDob}

                email={email}
                setEmail={setEmail}
                mobileNumber={mobileNumber}
                setMobileNumber={setMobileNumber}
                mobileCountryCode={mobileCountryCode}
                setMobileCountryCode={setMobileCountryCode}

                backOneStep={handleBack}
            />}

            {currentStep === 3 && isLocalForeign=="foreigner" && <EpUpload employmentPassFront={employmentPassFront} setEmploymentPassFront={setEmploymentPassFront} employmentPassBack={employmentPassBack} setEmploymentPassBack={setEmploymentPassBack}/>}

            {currentStep === 4 && isLocalForeign=="foreigner" && <AnalyzeEpForm 
                employmentPassFront={employmentPassFront} 
                employmentPassBack={employmentPassBack}
                finNumber={finNumber}
                setFinNumber={setFinNumber}
                occupation={occupation}
                setOccupation={setOccupation}
                epDateOfApplication={epDateOfApplication}
                setEpDateOfApplication={setEpDateOfApplication}
                epDateOfIssue={epDateOfIssue}
                setEpDateOfIssue={setEpDateOfIssue}
                epDateOfExpiry={epDateOfExpiry}
                setEpDateOfExpiry={setEpDateOfExpiry}
                epGender={epGender}
                setEpGender={setEpGender}
                employer={employer}
                setEmployer={setEmployer}
                epBackQR={epBackQR}
                setEpBackQR={setEpBackQR}

                backOneStep={handleBack}
            />}

            {currentStep === 5 && isLocalForeign=="foreigner" && <ProofOfAddressUpload proofOfAddress={proofOfAddress} setProofOfAddress={setProofOfAddress} s3Temp={s3Temp} setS3Temp={setS3Temp}/>}
            
            <Box display="flex" justifyContent="space-between" sx={{borderTop: '1px solid lightgrey', marginTop: '2rem'}}>
                {/**
                 * 
                 * back btn
                 * 
                 */}
                {(currentStep === 0) && <Button variant="outlined" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleBackStep}>
                    {t('general.back')}
                </Button>}
                
                {(currentStep > 0) && <Button variant="outlined" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleBack}>
                    {t('general.back')}
                </Button>}


                {/**
                 * 
                 * continue btn
                 * 
                 */}
                {currentStep === 0 && <Button variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} 
                    disabled={!isLocalForeign || isLocalForeign=="foreigner" && !countryCode}
                    onClick={handleStartIDUpload}>
                    {t('general.continue')}
                </Button> }

                {currentStep === 1 && isLocalForeign=="foreigner" && <Button disabled={!passportDocument} variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleFinishPassportUpload}>
                    {t('PassportVerification.continueB')}
                </Button> }
                {currentStep === 2 && isLocalForeign=="foreigner" && <Button disabled={!expiryDate || !issueDate || !passportNumber || !mobileNumber || !mobileCountryCode || !email || submitting} variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleContinuePassport}>
                    {t('PassportVerification.continueB')}
                </Button> }
                {currentStep === 3 && isLocalForeign=="foreigner" && <Button disabled={ !employmentPassFront || !employmentPassBack} variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleFinishEpUpload}>
                    {t('PassportVerification.continueB')}
                </Button> }

                {currentStep === 4 && isLocalForeign=="foreigner" && <Button disabled={ !finNumber || !employer || !occupation || !epGender || !epDateOfApplication || !epDateOfExpiry || !epDateOfIssue || submitting } variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleContinueEp}>
                    {t('IDVerification.continueB')}
                </Button> }

                {currentStep === 5 && isLocalForeign=="foreigner" && <Button disabled={submitting || !proofOfAddress} variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleFinishMiscDocsUpload}>
                    {t('PassportVerification.continueB')}
                </Button> }


                {currentStep === 1 && isLocalForeign=="local" && <Button disabled={!IdFrontPage || !IdBackPage} variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleFinishPassportUpload}>
                    {t('IDVerification.continueB')}
                </Button> }
                {currentStep === 2 && isLocalForeign=="local" && <Button disabled={!dob || !firstName || !lastName || !address.satisfied || !idNumber || !mobileNumber || !mobileCountryCode || !email || submitting } variant="contained" color="primary" sx={{ fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none', mt:3, mb:3}} onClick={handleContinueID}>
                    {t('IDVerification.continueB')}
                </Button> }
            </Box>
        </Container>

        <CustomDialog onClose={onEpDialogClose} open={epDialogOpen} 
            content={
                <Typography
                variant="h1"
                component="div"
                sx={{
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                    color: 'black',
                    mb: 2,
                    fontFamily: 'system-ui'
                }}
                >
                    
                    {t('AnalyzeEpForm.epQuestion1')}<br></br>
                    {t('AnalyzeEpForm.epQuestion2')} 
                </Typography>
            }
            footer={
                <>
                    <Button variant="contained" color="primary" 
                    sx={{ minWidth: '100px' ,fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none'}} 
                    onClick={onEpDialogAccept}>
                        {t('general.yes')}
                    </Button>
                    <Button variant="outlined" color="primary" 
                    sx={{ minWidth: '100px' ,fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none'}} 
                    onClick={onEpDialogReject}>   
                        {t('general.no')}
                    </Button>
                </>
            }
            />
        </Suspense>
    );
};

export default PassportIDVerification;