import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../locales/en/translation.json';
import translationCN from '../locales/cn/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  cn: {
    translation: translationCN
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
  });

export default i18n;