import Webcam from "react-webcam";
import React from "react";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useState, useEffect, useRef } from "react";
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';


function FaceVerificationResult({liveImage, livenessScore}) {
  const {t, i18n} = useTranslation();

    //liveness image check to prevent error
    const buffer = liveImage ? Buffer.from(liveImage.data) : liveImage;
    const base64String = liveImage ? buffer.toString('base64') : "";

    const isMobileWidth = () => {
        console.log(window.innerWidth)
        return window.innerWidth < 774;
    };
    useEffect(() => {
        console.log(liveImage)
        console.log(livenessScore)
    }, []);
    
  return (
    <Container
        maxWidth="false"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Typography
            variant="h1"
            component="span"
            sx={{
                fontSize: '22px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#333853',
                mt: 2,
                mb: 2,
                fontFamily: 'system-ui'
            }}
            >
            <p>{t('FaceVerification.checkPhoto')}</p>
        </Typography>
        {liveImage && 
        <div>
            <div style={{ 
                position: "relative", 
                width: isMobileWidth() ? "100%" : "650px", 
                height: isMobileWidth() ? "auto" : "500px"}}>
                <img src={`data:image/png;base64,${base64String}`} alt="Reference Image" />
            </div>
        </div>}
        {!liveImage && <div><p>Invalid image. Please try again.</p></div>}
        
    </Container>
  );
}

export default FaceVerificationResult;
