import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Container } from '@mui/material';
import FaceVerification from './FaceVerification';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import Submit from './Submit';
import { useNavigate } from 'react-router-dom';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import PassportIDVerification from './PassportIdVerification';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1976d2',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1976d2',
    },
  },
}));

const FlowRegular = () => {
  const {t} = useTranslation();
  const [kycLocale, setKycLocale] = useState("sg");
  const [activeStep, setActiveStep] = useState(0);
  const [passportDocument, setPassportDocument] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [issueDate, setissueDate] = useState(null);
  const [passportNumber, setPassportNumber] = useState(null);
  const [IdFrontPage, setIdFrontPage] = useState(null);
  const [IdBackPage, setIdBackPage] = useState(null);
  const [proofOfAddress, setProofOfAddress] = useState(null);

  const [employmentPassFront, setEmploymentPassFront] = useState(null);
  const [employmentPassBack, setEmploymentPassBack] = useState(null);

  const [finNumber, setFinNumber] = useState(null);
  const [employer, setEmployer] = useState(null);
  const [occupation, setOccupation] = useState(null);
  const [epDateOfApplication, setEpDateOfApplication] = useState(null);
  const [epDateOfIssue, setEpDateOfIssue] = useState(null);
  const [epDateOfExpiry, setEpDateOfExpiry] = useState(null);
  const [epGender, setEpGender] = useState(null);
  const [epBackQR, setEpBackQR] = useState(null);

  const [isEpUpload, setIsEpUpload] = useState(false);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [address, setAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    city: '',
    province: '',
    state: '',
    postal: '',
    satisfied: false
  });
  const [idNumber, setIdNumber] = useState(null);
  const [dob, setDob] = useState(null);
  const [auditVideo, setAuditVideo] = useState(null);
  const [liveTestDetails, setLiveTestDetails] = useState({});
  const [passportDetails, setpassportDetails] = useState({});
  const [IdDetails, setIdDetails] = useState({});
  const [createLivenessApiData, setCreateLivenessApiData] = useState("");
  const [s3Temp, setS3Temp] = useState({
    livenessImg:null,
    auditVideo:null,
    idFront:null,
    idBack:null,
    passport:null,
    proofOfAddress:null,
    epFront:null,
    epBack:null,
  })
  const [countryCode, setCountryCode] = useState("");
  const [refId, setRefId] = useState("");
  const [jwt, setJwt] = useState("");

  const [isLocalForeign, setIsLocalForeign] = useState("")

  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileCountryCode, setMobileCountryCode] = useState("");
  

  

  const navigate = useNavigate()
  const handleInvalid = () => {
      navigate('/invalid');
  }
  useEffect(()=>{
      const stores = JSON.parse(sessionStorage.getItem("mkyc"))
      const locale = sessionStorage.getItem("mkyc_locale")
      if(!stores){
          handleInvalid()
      }else{
        setRefId(stores.r)
        setJwt(stores.j)
        if(locale) setKycLocale(locale);
      }
  },[])
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
    if (activeStep === 1){
      if(isLocalForeign==="foreigner") {
        setIdDetails({})
        setpassportDetails({passportDocument, expiryDate, issueDate, passportNumber, firstName, lastName, dob, email, mobileCountryCode, mobileNumber}); 

        setS3Temp(prev=>({
            ...prev,
            idFront:null,
            idBack:null
        }))
      }
      if(isLocalForeign==="local"){
        setpassportDetails({}); 
        setIdDetails({IdFrontPage, firstName, lastName, address, idNumber, dob, email, mobileCountryCode, mobileNumber});

        setS3Temp(prev=>({
          ...prev,
          passport:null,
          proofOfAddress:null,
          epFront:null,
          epBack:null
        }))
      }
    }
  };

  const isMobileWidth = () => {
    return window.innerWidth < 774;
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const formatDate = (d) => {
    if(new Date(d) != "Invalid Date"){
      return new Date(d).toISOString()
    }else {
      return null
    }
  }
  const steps = [
    { label: t('Stepper.faceLiveness'), component: <FaceVerification  
                                  onContinueClick={handleNext} 
                                  liveTestDetails={liveTestDetails} 
                                  setLiveTestDetails={setLiveTestDetails}
                                  auditVideo={auditVideo} 
                                  setAuditVideo={setAuditVideo}
                                  createLivenessApiData={createLivenessApiData}
                                  setCreateLivenessApiData={setCreateLivenessApiData}
                                  s3Temp={s3Temp}
                                  setS3Temp={setS3Temp}
                                  
                                  />},

    { label: t('Stepper.PassportIDVerification'), component: <PassportIDVerification  
                                  kycLocale={kycLocale}
                                  onBackClick={handleBack} 
                                  onContinueClick={handleNext} 
                                  passportDocument={passportDocument} 
                                  setPassportDocument={setPassportDocument} 
                                  expiryDate={expiryDate} 
                                  setExpiryDate={setExpiryDate} 
                                  issueDate={issueDate} 
                                  setissueDate={setissueDate} 
                                  passportNumber={passportNumber} 
                                  setPassportNumber={setPassportNumber}

                                  IdFrontPage={IdFrontPage} 
                                  setIdFrontPage={setIdFrontPage} 
                                  IdBackPage={IdBackPage} 
                                  setIdBackPage={setIdBackPage} 
                                  firstName={firstName} 
                                  setFirstName={setFirstName} 
                                  lastName={lastName} 
                                  setlastName={setlastName} 
                                  address={address} 
                                  setAddress={setAddress}
                                  idNumber={idNumber} 
                                  setIdNumber={setIdNumber} 
                                  dob={dob} 
                                  setDob={setDob}

                                  email={email}
                                  setEmail={setEmail}
                                  mobileNumber={mobileNumber}
                                  setMobileNumber={setMobileNumber}
                                  mobileCountryCode={mobileCountryCode}
                                  setMobileCountryCode={setMobileCountryCode}

                                  s3Temp={s3Temp}
                                  setS3Temp={setS3Temp}
                                  countryCode={countryCode}
                                  setCountryCode={setCountryCode}

                                  isLocalForeign={isLocalForeign}
                                  setIsLocalForeign={setIsLocalForeign}

                                  proofOfAddress={proofOfAddress}
                                  setProofOfAddress={setProofOfAddress}

                                  employmentPassFront={employmentPassFront}
                                  setEmploymentPassFront={setEmploymentPassFront}
                                  employmentPassBack={employmentPassBack}
                                  setEmploymentPassBack={setEmploymentPassBack}
                                  finNumber={finNumber}
                                  setFinNumber={setFinNumber}
                                  isEpUpload={isEpUpload}
                                  setIsEpUpload={setIsEpUpload}

                                  occupation={occupation}
                                  setOccupation={setOccupation}
                                  epDateOfApplication={epDateOfApplication}
                                  setEpDateOfApplication={setEpDateOfApplication}
                                  epDateOfIssue={epDateOfIssue}
                                  setEpDateOfIssue={setEpDateOfIssue}
                                  epDateOfExpiry={epDateOfExpiry}
                                  setEpDateOfExpiry={setEpDateOfExpiry}
                                  epGender={epGender}
                                  setEpGender={setEpGender}
                                  employer={employer}
                                  setEmployer={setEmployer}
                                  
                                  epBackQR={epBackQR}
                                  setEpBackQR={setEpBackQR}
                                  />},


    { label: t('Stepper.confirm'), component: <Submit 
                                  onBackClick={handleBack} 
                                  createLivenessApiData={createLivenessApiData}
                                  IdDetails={IdDetails} 
                                  passportDetails={passportDetails} 
                                  liveTestDetails={liveTestDetails}
                                  auditVideo={auditVideo}
                                  s3Temp={s3Temp}
                                  refId={refId}
                                  jwt={jwt}
                                  activeStep={activeStep}
                                  setActiveStep={setActiveStep}

                                  firstName={firstName}
                                  lastName={lastName}
                                  dob={dob?.$d ? formatDate(dob.$d) : null}
                                  idNumber={idNumber}
                                  addressLine1={address.addressLine1}
                                  addressLine2={address.addressLine2}
                                  city={address.city}
                                  province={address.province}
                                  state={address.state}
                                  postal={address.postal}
                                  expiryDate={expiryDate?.$d ? formatDate(expiryDate.$d) : null}
                                  issueDate={issueDate?.$d ? formatDate(issueDate.$d) : null}
                                  passportNumber={passportNumber}

                                  setIdFrontPage={setIdFrontPage}
                                  setIdBackPage={setIdBackPage}
                                  setPassportDocument={setPassportDocument}
                                  setProofOfAddress={setProofOfAddress}

                                  passportDocument={passportDocument} 
                                  IdFrontPage={IdFrontPage} 
                                  IdBackPage={IdBackPage} 
                                  proofOfAddress={proofOfAddress}

                                  email={email}
                                  mobileNumber={mobileNumber}
                                  mobileCountryCode={mobileCountryCode}
                                  

                                  isLocalForeign={isLocalForeign}
                                  setIsLocalForeign={setIsLocalForeign}

                                  employmentPassFront={employmentPassFront}
                                  employmentPassBack={employmentPassBack}
                                  finNumber={finNumber}
                                  occupation={occupation}
                                  epDateOfApplication={epDateOfApplication}
                                  epDateOfIssue={epDateOfIssue}
                                  epDateOfExpiry={epDateOfExpiry}
                                  epGender={epGender}
                                  employer={employer}
                                  
                                  epBackQR={epBackQR}
                                  isEpUpload={isEpUpload}
                                  />},
                                  
  ];

  return (
    <Suspense fallback="...loading">
    <Container
      maxWidth="false"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // height: '770px',
        // width: '774px',
        width: 'calc(100% - 2rem)',
        maxWidth: '774px',
        margin: '0 auto',
        minHeight: '694px',
        border: '1px solid lightgrey',
        borderRadius: '4px',
        position:'relative'
      }}
    >
      <Stepper activeStep={activeStep} connector={<QontoConnector />} sx={{borderBottom: '1px solid lightgrey', borderRadius: '4px', marginBottom: '2rem', height: '100px', marginTop: '2rem'}} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
          <StepLabel
              sx={{
                '& .MuiStepIcon-root': {
                  width: isMobileWidth() ? '24px' : '32px',
                  height: isMobileWidth() ? '24px' : '32px',
                  fontSize: '18px',
                },
                '& .MuiStepLabel-label': {
                  marginTop: '0.5rem',
                  fontSize: isMobileWidth() ? '12px' : '16px',
                },
              }}
            >{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {steps[activeStep].component}
      </div>
    </Container>
    </Suspense>
  );
};

export default FlowRegular;