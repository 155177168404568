import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect, useRef } from "react";
import { Container, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import _ from 'lodash'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import axios from "axios";
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MobileInput from '../common/MobileInput';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import jsQR from "jsqr";

import CustomDialog from '../common/CustomDialog';


const CustomSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select': {
        color: '#595D70',
       '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
        color: 'grey',
    },
    '& .MuiMenu-paper': {
        backgroundColor: '#F5FBFF',
    },
}));

const { REACT_APP_KYC_BACKEND } = process.env;

export default (props) => {
  const { employmentPassFront, employmentPassBack, finNumber, setFinNumber, occupation, setOccupation, 
    epDateOfApplication,
    setEpDateOfApplication,
    epDateOfIssue,
    setEpDateOfIssue,
    epDateOfExpiry,
    setEpDateOfExpiry,
    epGender,
    setEpGender,   
    employer,
    setEmployer,
    epBackQR,
    setEpBackQR,
    backOneStep } = props;


    const {t} = useTranslation();
    const epFrontTextExtractURL = REACT_APP_KYC_BACKEND + "/textExtract/extractText/epFront/singapore"
    const epBackTextExtractURL = REACT_APP_KYC_BACKEND + "/textExtract/extractText/epBack/singapore"
    const [loading, setLoading] = useState(true);

    const [dialogOpen, setDialogOpen] = useState(false);

    

    useEffect(() => {
        const getQR = async () => {
            if(employmentPassBack){
                
                var img = new Image();
                img.src = employmentPassBack;

                img.onload = function() {
                    var canvas = document.createElement('canvas');
                    var ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            
                    var code = jsQR(imageData.data, imageData.width, imageData.height);

                    console.log(code)
                    if (code) {
                        setEpBackQR(code.data)
                    } else {
                        setEpBackQR(null)
                    }
                };
            }else{
                setEpBackQR(null)
            }
        }
        const captureFrontDetails = async (image) => {
            const requestData = {
                img: image,
            };
            return await axios.post(epFrontTextExtractURL, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        };
        const captureBackDetails = async (image) => {
            const requestData = {
                img: image,
            };
            return await axios.post(epBackTextExtractURL, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        };
        const fetchData = async () => {
            try {
                getQR()
                await Promise.all([
                    captureFrontDetails(employmentPassFront),
                    captureBackDetails(employmentPassBack),
                ]).then(res=>{
                    let resFinNumber = res[0].data.finNumber || res[1].data.finNumber || ""
                    if(resFinNumber){
                        setFinNumber(resFinNumber)
                    }else{
                        setDialogOpen(true)
                    }
                })
            } catch (error) {
              // Handle error if needed
              console.error(error)
            } finally {
              setLoading(false);
            }
        };
        fetchData();
        
    }, []);

    //dialog
    const onDialogClose = (event, reason) => {
        if(reason == "backdropClick" || reason == "escapeKeyDown") return;
        setDialogOpen(false)
    }
    const onDialogAccept = (event) => {
        //accept reupload
        setDialogOpen(false)
        backOneStep()
    }
    const onDialogReject = (event) => {
        //reject reupload
        setDialogOpen(false)
    }

  return (
    <Suspense fallback="...loading"><div>
        { !loading && <Container
        disableGutters
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            
        }}
        >
            <Typography
            variant="h1"
            component="span"
            sx={{
                fontSize: '22px',
                fontWeight: 550,
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: 'black',
                mt: 0,
                mb: 5,
                ml: 1,
                fontFamily: 'system-ui'
            }}
            >
                {t('AnalyzeIDForm.confirmInformation')}
            </Typography>
            <Typography
            variant="h1"
            component="span"
            sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: 'black',
                mb: 5,
                ml: 1,
                fontFamily: 'system-ui'
            }}
            >
                {t('AnalyzeIDForm.content')}
            </Typography>
            <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '34ch', borderBottom: 0 },
                mt: 1
            }}
            noValidate
            >
        
                <div>
                    <TextField
                        id="outlined-controlled"
                        shrink={false}
                        label= {t('AnalyzeEpForm.finNumber')}
                        value={finNumber}
                        variant="outlined"
                        onChange={ev=>{ setFinNumber(ev.target.value) }} 
                        InputLabelProps={{
                            shrink: true, 
                        }}
                    />
                    <TextField
                        id="outlined-controlled"
                        shrink={false}
                        label= {t('AnalyzeEpForm.occupation')}
                        value={occupation}
                        variant="outlined"
                        onChange={ev=>{ setOccupation(ev.target.value) }} 
                        InputLabelProps={{
                            shrink: true, 
                        }}
                    />
                    <TextField
                        id="outlined-controlled"
                        shrink={false}
                        label= {t('AnalyzeEpForm.employer')}
                        value={employer}
                        variant="outlined"
                        onChange={ev=>{ setEmployer(ev.target.value) }} 
                        InputLabelProps={{
                            shrink: true, 
                        }}
                    />

                    {/** todo: adjust responsive */}
                    <FormControl sx={{width:'40.5%',margin:'8px'}}>
                        <InputLabel>{t('AnalyzeEpForm.epGender')}</InputLabel>
                        <CustomSelect
                            value={epGender}
                            onChange={(ev)=>setEpGender(ev.target.value)}
                        >
                            
                            <MenuItem value="M">
                                <Box display="flex" alignItems="center">
                                    {t('AnalyzeEpForm.male')}
                                </Box>
                            </MenuItem>
                            <MenuItem value="F">
                                <Box display="flex" alignItems="center">
                                    {t('AnalyzeEpForm.female')}
                                </Box>
                            </MenuItem>
                        </CustomSelect> 
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t('AnalyzeEpForm.epDateOfApplication')}
                            value={epDateOfApplication}
                            onChange={(date)=>setEpDateOfApplication(date)}
                            format="DD/MM/YYYY" // Customize the date format
                            mask="__/__/____" // Add a mask for better input control (optional)
                            renderInput={(params) => (
                            <TextField {...params} variant="filled" />
                            )}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t('AnalyzeEpForm.epDateOfIssue')}
                            value={epDateOfIssue}
                            onChange={(date)=>setEpDateOfIssue(date)}
                            format="DD/MM/YYYY" // Customize the date format
                            mask="__/__/____" // Add a mask for better input control (optional)
                            renderInput={(params) => (
                            <TextField {...params} variant="filled" />
                            )}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t('AnalyzeEpForm.epDateOfExpiry')}
                            value={epDateOfExpiry}
                            onChange={(date)=>setEpDateOfExpiry(date)}
                            format="DD/MM/YYYY" // Customize the date format
                            mask="__/__/____" // Add a mask for better input control (optional)
                            renderInput={(params) => (
                            <TextField {...params} variant="filled" />
                            )}
                        />
                    </LocalizationProvider>
                    
                </div>
            </Box>
        </Container>}
        {loading && <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px" // Set the desired height (adjust as needed)
          marginTop="170px"
          marginBottom="170px"
        >
          <CircularProgress size={100} color="primary" /> {/* Adjust the size and color */}
        </Box>}


        <CustomDialog onClose={onDialogClose} open={dialogOpen} 
            content={
                <Typography
                variant="h1"
                component="div"
                sx={{
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                    color: 'black',
                    mb: 2,
                    fontFamily: 'system-ui'
                }}
                >
                    {t('AnalyzeEpForm.ocrRetryMsg')}<br />
                    {t('AnalyzeEpForm.ocrRetryConfirmation')}
                </Typography>
            }
            footer={
                <>
                    <Button variant="contained" color="primary" 
                    sx={{ minWidth: '100px' ,fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none'}} 
                    onClick={onDialogAccept}>
                        {t('general.yes')}
                    </Button>
                    <Button variant="outlined" color="primary" 
                    sx={{ minWidth: '100px' ,fontFamily: 'system-ui', borderRadius: '20px', fontSize: '17px',fontWeight: 500,textTransform: 'none'}} 
                    onClick={onDialogReject}>   
                        {t('general.no')}
                    </Button>
                </>
            }
            />

        
    </div>
    </Suspense>
  );
}