import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
const handlePrivacyPolicy = () => {
    // console.log("clicked privacy notice");
    window.open(`https://central.meyzer360.com/privacy`)
}

const handleTermOfService = () => {
    // console.log("clicked term of service");
    window.open(`https://central.meyzer360.com/terms`)
}
const isMobileWidth = () => {
    console.log(window.innerWidth)
    return window.innerWidth < 774;
};

function BottomBar() {
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                p: 0.5,
                border: '1px solid lightgrey',
                borderTop: 0,
                // width: isMobileWidth() ? '100vw' : '774px' ,
                width: 'calc(100% - 2rem)',
                maxWidth: '774px',
                minHeight: '30px'
            }}

            >
            <Container maxWidth="false" sx={{ display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 0}}>
                <Box component="img" src={require('../../resources/MEYZER.png')} sx={{ height: 14}} alt="Logo" />
                { !isMobileWidth() && <Typography variant="body2" color="grey" align="left" sx={{ fontFamily: 'Source Sans Pro', marginLeft: '8px' }}>
                    <p>v01-01</p>
                </Typography>}
                { !isMobileWidth() && <Typography variant="body2" color="grey" align="left" sx={{ fontFamily: 'Source Sans Pro', marginLeft: '8px' }}>
                    <p>|</p>
                </Typography>}
                { !isMobileWidth() && <Typography variant="body2" color="#1976d2" component="span" sx={{ marginLeft: '8px'}}>
                    <span style={{ textDecoration: '', cursor: 'pointer', fontSize: '14px'}} onClick={handleTermOfService}>
                        {t('bottombar.term')}
                    </span>
                </Typography>}
                { !isMobileWidth() && <Typography variant="body2" color="grey" align="left" sx={{ fontFamily: 'Source Sans Pro', marginLeft: '8px' }}>
                    <p>|</p>
                </Typography>}
                { !isMobileWidth() && <Typography variant="body2" color="#1976d2" component="span" sx={{ marginLeft: '8px'}}>
                    <span style={{ textDecoration: '', cursor: 'pointer', fontSize: '14px'}} onClick={handlePrivacyPolicy}>
                        {t('bottombar.privacy')}
                    </span>
                </Typography>}


            </Box>
                <Typography style={{textAlign:'right'}} variant="body2" color="grey" component="span">
                    {"© "}
                    {new Date().getFullYear()}
                    {t('bottombar.rights')}
                </Typography>
            </Container>
        </Box>
    );
}

export default function WrappedBottomBar() {
    return (
        <Suspense fallback="...loading">
            <BottomBar />
        </Suspense>
    )
};